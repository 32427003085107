<template>
  <BackofficeBase>
    <h1>File Uploader</h1>
    <form>
      <input type="file" v-on:change="file_upload_1_change" ref="file_upload_1"><br>
      <div v-if="uploading">Uploading...</div>
    </form>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase"
import BackendModel from "../../models/BackendModel"

export default {
  name: 'BackofficeFileUploader',
  components: {
    BackofficeBase
  },
  data() {
    return {
      file_upload_1 : '',
      uploading : false
    }
  },
  methods : {
    async file_upload_1_change() {
      this.uploading = true
      this.file_upload_1 = this.$refs.file_upload_1.files[0]
      let backendModel = new BackendModel()
      let formData = new FormData()
      formData.append('file_upload_1', this.file_upload_1)
      await backendModel.backendRequest("/Api/service/backoffice/upload_file", formData)
      this.uploading = false
    }
  }
}
</script>
